@import '../../../../theme/breakpoints';

.button {
  align-items: center;
  background: var(--color-sem-cta-bg-primary-default);
  border: 0;
  border-radius: var(--border-radius-sem-cta-max);
  color: var(--color-sem-cta-fg-primary-default);
  cursor: pointer;
  display: flex;
  font-size: var(--text-typeset-font-size-m);
  font-style: normal;
  font-weight: 600;
  gap: var(--space-com-cta-margin);
  height: var(--size-sem-cta-height-medium);
  justify-content: center;
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  line-height: normal;
  padding: 0 var(--space-com-cta-padding);
  text-decoration: none;

  .count {
    font-weight: 400;
  }

  .label {
    display: none;
    @media #{$medium} {
      display: inline;
    }
  }
}
